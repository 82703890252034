import React, { useState, useEffect } from "react";
import Axios from "utils/Axios";
import MKButton from "components/MKButton";
import { ButtonGroup, Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import coverImageDefault from "../../../assets/images/groove-sign-up.jpg";
import Avatar from "@mui/material/Avatar";
import truncate from "truncate-html";
import MKPagination from "components/MKPagination";

const itemsPerPage = 9;

function Events() {
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState("All");

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const imageUrl = (url) => process.env.REACT_APP_BASE_IMAGE_URL + url;

  const gotoEventDetails = (eventId) => {
    navigate(`/admin/events/single/${eventId}`);
  };

  const truncateHtml = (htmlContent, length = 100) => {
    return truncate(htmlContent, {
      length: length,
      ellipsis: "...",
      keepImageTag: true,
    });
  };

  // Fetch events initially and store them
  useEffect(() => {
    Axios.get(`?type=adminEvent&token=${token}`)
      .then((response) => {
        const eventData = response.data;
        const flattenedEvents = eventData.flatMap((dateGroup) =>
          dateGroup.events?.map((event) => ({
            id: event.id,
            title: event.title,
            description: event.description,
            time: event.time,
            image: event.image,
            location: event.location,
            ticketPrice: event.ticketPrice,
            date: event.eventDate,
            is_active: event.is_active,
          }))
        );
        setAllEvents(flattenedEvents);
        setEvents(flattenedEvents);
        setTotalPages(Math.ceil(flattenedEvents.length / itemsPerPage));
      })
      .catch((error) => console.error("Failed to fetch events data", error));
  }, [token]);

  // Filter events based on the selected filter and paginate
  const filterEvents = (filterType) => {
    let filtered = allEvents;
    if (filterType === "Approved") {
      filtered = allEvents.filter((event) => event.is_active === "1");
    } else if (filterType === "Pending") {
      filtered = allEvents.filter((event) => event.is_active === "0");
    }
    setEvents(filtered);
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
    setPage(1); // Reset to first page on filter change
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    filterEvents(newFilter);
  };

  // Handle page change for pagination
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Paginate the filtered data
  const paginatedEvents = events.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <div className="promoterEvents">
      <div className="AddEvent flex justify-between">
        <div>
          <div className="lg:mx-12 my-2">
            <div className="text-2xl font-bold py-0 text-primary">Events</div>
            <div className="text-sm font-light py-2">
              All the available events will be listed here, according to their state of approval.
            </div>
          </div>
        </div>
        <div className="addEventText">
          <ButtonGroup variant="contained" color="info" className="mr-4">
            <Button
              sx={{
                backgroundColor: filter === "All" ? "#4E0249" : "#ffffff",
                color: "white",
              }}
              onClick={() => handleFilterChange("All")}
            >
              All
            </Button>
            <Button
              sx={{
                backgroundColor: filter === "Approved" ? "#4E0249" : "#ffffff",
                color: "white",
              }}
              onClick={() => handleFilterChange("Approved")}
            >
              Approved
            </Button>
            <Button
              sx={{
                backgroundColor: filter === "Pending" ? "#4E0249" : "#ffffff",
                color: "white",
              }}
              onClick={() => handleFilterChange("Pending")}
            >
              Pending
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6 p-4">
        {paginatedEvents.map((event) => (
          <div
            onClick={() => gotoEventDetails(event.id)}
            key={event.id}
            className="bg-gray-800 text-white p-4 rounded-lg shadow-lg relative cursor-pointer"
          >
            <div className="flex gap-x-8">
              <Avatar
                alt={event.title}
                src={imageUrl(event.image) || coverImageDefault}
                sx={{ width: 56, height: 56 }}
                variant="rounded"
              />
              <div className="">
                <h2 className="text-lg font-bold">{event.title}</h2>
                <p className="text-xs font-thin text-green-400">{event.location}</p>
                {event.is_active === "0" ? (
                  <div>
                    <p className="text-xs font-thin text-white bg-yellow-600 max-w-fit px-2 py-0.5 rounded-md">
                      Pending
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mt-10">
              <p
                className="text-sm text-gray-400"
                dangerouslySetInnerHTML={{
                  __html: truncateHtml(event.description, 100),
                }}
              ></p>
            </div>
            <div className="mt-5">
              <p className="text-xs text-gray-400 mt-2">
                Event Date: {new Date(event.date).toLocaleString()}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <MKPagination count={totalPages} page={page} onChange={handlePageChange}>
        <MKPagination item disabled={page === 1} onClick={() => handlePageChange(null, page - 1)}>
          <Icon>keyboard_arrow_left</Icon>
        </MKPagination>
        {[...Array(totalPages).keys()].map((pageNumber) => (
          <MKPagination
            item
            key={pageNumber + 1}
            onClick={() => handlePageChange(null, pageNumber + 1)}
            active={page === pageNumber + 1}
          >
            {pageNumber + 1}
          </MKPagination>
        ))}
        <MKPagination item disabled={page === totalPages} onClick={() => handlePageChange(null, page + 1)}>
          <Icon>keyboard_arrow_right</Icon>
        </MKPagination>
      </MKPagination>
    </div>
  );
}

export default Events;
