import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Button, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Axios from "utils/Axios";
import { useParams } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function EventSettings() {
  const [showAddImage, setShowAddImage] = useState(false);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const { id } = useParams();
  const token = localStorage.getItem("token");
// const promoterInfo = JSON.parse(localStorage.getItem("userInfo"));
// if(promoterInfo){
//   const promoterId = promoterInfo.uid;
//   console.log(promoterId, "THE PROMOTER ID");
// }
  useEffect(() => {
    getEventMedia();
  }, [id]);

  const onChange = (e) => {
    const newFiles = e.target.files || e.dataTransfer.files;
    handleFiles(newFiles);
  };

  const handleFiles = (newFiles) => {
    const validFiles = Array.from(newFiles).filter((file) => file.type.startsWith("image/"));
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    setDragging(false);
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const removeAllFiles = () => {
    setFiles([]);
  };

  const discard = () => {
    setShowAddImage(false);
    removeAllFiles();
  };

  const saveEventMedia = () => {
    setLoading(true); // Start loading
    const formData = new FormData();
    formData.append("type", "eventMedia");
    formData.append("event_id", id);
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    Axios.post("", formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        discard()
        getEventMedia();
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getEventMedia = () => {
    Axios.get(`?type=eventMedia&event_id=${id}`)
      .then((response) => {
        setImages(response.data || []);
        console.log(images);
      })
      .catch((error) => {
        console.error("Error fetching event media:", error);
      });
  };

  const imageUrl = (image) => {
    const parts = image.split('_');
    if (parts.length >= 3) {
      parts.splice(3, 0, 'img_250');
      image = parts.join('_');
    }
    return `${process.env.REACT_APP_BASE_IMAGE_URL}gallery/${image}`;
  };
  return (
    <Box sx={{ width: "100%" }}>
      <div className="flex justify-between py-2.5">
        <div>
          <div className="lg:mx-12 my-2">
            <div className="text-2xl font-bold py-0 text-primary">Event Photo Gallery</div>
            <div className="text-sm font-light py-2">
              Add your Photos from the Event.  These will be displayed on the Previous Event Page.
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <button
            onClick={() => {
              setShowAddImage(!showAddImage);
            }}
            type="button"
            className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
          >
            Add New Images
          </button>
        </div>
      </div>
      {showAddImage ? (
        <div className="py-5 gallery-new-images">
          <div style={{ marginTop: "20px" }}>
            <div
              className={`dropZone ${dragging ? "dropZone-over" : ""}`}
              onDragEnter={() => setDragging(true)}
              onDragLeave={() => setDragging(false)}
              onDrop={(e) => {
                e.preventDefault();
                onChange(e);
              }}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className="dropZone-info">
                <InsertDriveFileIcon style={{ fontSize: "48px" }} />
                <br />
                <CloudUploadIcon className="dropZone-title" style={{ fontSize: "48px" }} />
                <span className="dropZone-title">
                  <strong>Drop images here or click to upload</strong>
                </span>
                <div className="dropZone-upload-limit-info">
                  <div>Maximum file size: 15 MB</div>
                </div>
              </div>
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={onChange}
                style={{ display: "none" }}
                id="file-input"
              />
              <label
                htmlFor="file-input"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                }}
              />
            </div>

            {files.length > 0 && (
              <div style={{ marginTop: "20px" }}>
                <button
                  onClick={removeAllFiles}
                  type="button"
                  className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                >
                  Remove All Images
                </button>
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
                  {files.map((file, index) => (
                    <div key={index} style={{ margin: "10px", position: "relative" }}>
                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        onClick={() => removeFile(index)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-end py-2.5">
            <button
              type="button"
              onClick={discard}
              className="text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900"
            >
              Discard
            </button>
            <button
              onClick={saveEventMedia}
              type="button"
              className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
              disabled={loading} // Disable the button when loading
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Save All"}
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {/* Define four inner divs that always exist */}
        {[0, 1, 2, 3].map((col) => (
          <div key={col} className="grid gap-4">
            {images
              .filter((_, index) => index % 4 === col) // Dynamically assign images to this column
              .map((image, index) => (
                <div key={image.id || index}>
                  <img
                    className="h-auto max-w-full rounded-lg"
                    src={imageUrl(image.title)} // Generate the dynamic URL
                    alt={image.title} // Image alt text
                  />
                </div>
              ))}
          </div>
        ))}
      </div>
    </Box>
  );
}
