import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import DefaultNavbar from "hanyeleo/Navbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "navroutes";
import footerRoutes from "footer.routes";
import Axios from "utils/Axios";
import truncate from "truncate-html";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { weekday: "short", day: "2-digit", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
};
const imageUrl = (url) => {
  return process.env.REACT_APP_BASE_IMAGE_URL + url;
};

const EventDetails = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [events, setEvents] = useState(null);
  const [showDescription, setShowDescription] = useState(true);
  const [images, setImages] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [show, setShow] = useState(false);

  const galleryImageUrl = (image) => {
    const parts = image.split('_');
    if (parts.length >= 3) {
      parts.splice(3, 0, 'img_250');
      image = parts.join('_');
    }
    return `${process.env.REACT_APP_BASE_IMAGE_URL}gallery/${image}`;
  };

  //react router navigate
  const navigate = useNavigate();

  const truncateHtml = (htmlContent, length = 100) => {
    return truncate(htmlContent, {
      length: length,
      ellipsis: "...",
      keepImageTag: true,
    });
  };
  const gotoEventDetails = (eId) => {
    setEvent(null);
    navigate(`/event/${eId}`);
    fetchEventDetails();
  };
  const fetchEventDetails = async () => {
    Axios.get(`?type=event&id=${eventId}`)
      .then((response) => {
        setEvent(response.data);
        fetchPromoterEvents(response.data.promoter_id);
      })
      .catch((error) => {
        console.error("Failed to fetch events data", error);
      });
  };
  const fetchPromoterEvents = async (id) => {
    Axios.get(`?type=event&promoter_id=${id}`)
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch events data", error);
      });
  };
  const getEventMedia = () => {
    Axios.get(`?type=eventMedia&event_id=${eventId}`)
      .then((response) => {
        setImages(response.data || []); 
        console.log(response.data, "IMAGESSS");
      })
      .catch((error) => {
        console.error("Error fetching event media:", error);
      });
  };

 
  useEffect(() => {
    // Check if token exists in localStorage on component mount
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true); // User is logged in
    }

    fetchEventDetails();
    getEventMedia();
  }, [eventId]);

  if (!event) {
    return (
      <MKBox
        minHeight="70vh"
        width="100%"
        sx={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <MKTypography variant="h4">Loading...</MKTypography>
      </MKBox>
    );
  }
  // Determine the number of images to show for access level 0 and whether to show a message
  const maxImagesToShow = !isLoggedIn ? 4 : images.length;

  //get the image id on click
  const handleImageClick = (imageIndex, imageId) => {
    if (!isLoggedIn) {
      setShow(true);
    } else {
      navigate(`/event-image/${imageIndex}/${eventId}/${imageId}`);
    }
  };

  const handleButtonClick = () => {
    if(event.location_url == null || event.location_url == ""){
      return
    }
    
    window.open(event.location_url, "_blank", "noopener,noreferrer");
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <DefaultNavbar routes={routes} relative center />
      <img
        src={imageUrl(event.image)}
        alt={event.title}
        className="md:w-2/5 w-fit sm:hidden block "
      />
      <div className="bg-gray-100 ">
        <Container>
          <div className="flex flex-col-reverse md:flex-row justify-between">
            <div className="flex flex-col p-6 md:p-0 justify-center">
              <p className="text-3xl md:text-5xl font-bold text-black mb-4">{event.title}</p>
              <MKTypography variant="body2" gutterBottom>
                Date: {formatDate(event.date)}
              </MKTypography>
              <MKTypography variant="body2" gutterBottom>
                Time: {event.time}
              </MKTypography>
              <MKTypography variant="body2" gutterBottom>
                Promoter: {event.promoter}
              </MKTypography>
              <MKTypography variant="body2" gutterBottom></MKTypography>
              <MKTypography variant="body2" gutterBottom></MKTypography>
              <div className="flex flex-col">
                <button
                  type="button"
                  className="text-white bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:ring-purple-400 font-medium rounded-lg text-sm py-3 me-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
                  onClick={handleButtonClick}
                >
                  {event.location}
                </button>
                {/* {event?.date ? (
                  new Date(event.date) > new Date() ? (
                    <div>
                    <MKButton
                      variant="gradient"
                      color="info"
                      size="large"
                      sx={{ py: 2, px: 2, mt: 2 }}
                    >
                      Ticket: {event.ticketPrice}&nbsp;
                      <Icon sx={{ ml: 6 }}>arrow_right</Icon>
                    </MKButton>
                    </div>
                  ) : (
                    <button
                      onClick={() => {
                        setShowDescription(!showDescription);
                      }}
                      type="button"
                      className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                    >
                      {showDescription ? "Show Gallery" : "Show Description"}
                    </button>
                  )
                ) : // Render nothing if the data is not loaded yet
                null} */}
              </div>
            </div>
            <img
              src={imageUrl(event.image)}
              alt={event.title}
              className="md:w-2/5 w-fit sm:block hidden"
            />
          </div>
        </Container>
      </div>
      <Container sx={{ mt: 5 }}>
        <div>
          {event?.date && new Date(event?.date) < new Date() ? (
            images.length > 0 ? (
              <div>
                <MKTypography variant="h4" gutterBottom>
                  Event Gallery
                </MKTypography>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {/* Define four inner divs that always exist */}
                  {[0, 1, 2, 3].map((col) => (
                    <div key={col} className="grid gap-4">
                      {images
                        .slice(0, maxImagesToShow) // Use maxImagesToShow based on access level
                        .filter((_, index) => index % 4 === col) // Dynamically assign images to columns
                        .map((image, index) => (
                          <div key={image.id || index}>
                            <img
                              className="h-auto max-w-full rounded-lg"
                              src={galleryImageUrl(image.title)} // Generate the dynamic URL
                              alt={image.title} // Image alt text
                              onClick={() => handleImageClick(image.title, image.id)}
                            />
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
                <React.Fragment>
                  <Dialog
                    open={show}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    className="p-5"
                  >
                    <DialogTitle>{"Warning"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        To view images in full size, please{" "}
                        <a href="/sign-up/" className="text-blue-500">
                          Create a free account
                        </a>{" "}
                        or{" "}
                        <a href="/sign-in/" className="text-blue-500">
                          log in here
                        </a>
                        .
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </React.Fragment>

                {/* Show the signup prompt only if there are more than 4 images and the access level is 0 */}
                {!isLoggedIn && images.length > 4 && (
                  <div className="text-center mt-4">
                    <p>
                      Want to see the full gallery?{" "}
                      <a href="/sign-up/" className="text-blue-500">
                        Create a free account
                      </a>{" "}
                      to unlock all images
                    </p>
                  </div>
                )}
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {new Date(event?.date) > new Date() || showDescription ? (
            <MKBox mt={3}>
              <MKTypography variant="h6" gutterBottom>
                Event Details
              </MKTypography>
              <MKBox
                sx={{
                  "& p": {
                    marginBottom: 2,
                  },
                  "& img": {
                    maxWidth: "100%",
                    height: "auto",
                    marginBottom: 2,
                  },
                  "& h2, & h3, & h4, & h5, & h6": {
                    marginTop: 3,
                    marginBottom: 1,
                  },
                  "& ul, & ol": {
                    marginLeft: 4,
                    marginBottom: 2,
                  },
                }}
                className="text-gray-900 font-light"
                dangerouslySetInnerHTML={{ __html: event.description }}
              />
            </MKBox>
          ) : (
            ""
          )}
        </div>
      </Container>

      <Container sx={{ mt: 5 }}>
        <div className="text-start mt-16 mb-3">
          <p className="text-xl md:text-3xl font-bold text-gray-900 mb-4">
            <span className="border-b-4 border-purple-700">More</span> from {event.promoter}
          </p>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6 ">
          {events?.map((event) => {
            if (event.id !== eventId) {
              return (
                <div
                  onClick={() => gotoEventDetails(event.id)}
                  key={event.id}
                  className="bg-white text-gray-800 p-4 border rounded-lg shadow-lg relative cursor-pointer"
                >
                  <div className="flex gap-x-8">
                    <Avatar
                      alt={event.title}
                      src={imageUrl(event.image) || coverImageDefault}
                      sx={{ width: 56, height: 56 }}
                      variant="rounded"
                    />
                    <div className="">
                      <h2 className="text-lg font-bold">{event.title}</h2>
                      <p className="text-xs font-thin text-purple-700">{event.location}</p>
                    </div>
                  </div>
                  <div className="mt-10">
                    <p
                      className="text-sm text-gray-600"
                      dangerouslySetInnerHTML={{
                        __html: truncateHtml(event.description, 100),
                      }}
                    ></p>
                  </div>
                  <div className="mt-5">
                    <p className="text-xs text-gray-600 mt-2">
                      Event Date: {event.date}, {event.time}
                    </p>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </Container>

      <DefaultFooter content={footerRoutes} />
    </>
  );
};

export default EventDetails;
