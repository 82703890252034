import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    id: "",
    title: "East Africa",
};

const citySlice = createSlice({
    name: "city",
    initialState,
    reducers: {
        setCity: (state, action) => {
            state.id = action.payload.id;
            state.title =  action.payload.title;
        },
    },
});

export const { setCity } = citySlice.actions;
export default citySlice.reducer;