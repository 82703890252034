// React and Hooks
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

function Footer() {
  const location = useLocation();

  useEffect(() => {
    // Check if the script is already added
    const scriptId = 'nyimbo-script';
    if (!document.getElementById(scriptId)) {
      // Define _tid on the window object
      window._tid = "g87igjghf5gg83esded9";

      // Create a script element
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://a2k.rw/st/nyimbo.js';
      script.async = true;

      // Append to head
      document.head.appendChild(script);

      // Cleanup function to remove the script when the component unmounts
      return () => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
        // Clean up the global variable if necessary
        delete window._tid;
      };
    }
  }, [location]); // Run effect on navigation


  // useEffect(() => {
  //   const scriptContent = `
  //     var _tid = "g87igjghf5gg83esded9"; 
  //     const _hati = document.createElement('script');
  //     _hati.src = 'https://a2k.rw/st/nyimbo.js';
  //     document.head.appendChild(_hati); 
  //   `;

  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.innerHTML = scriptContent;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} by{" "}
      <MKTypography
        component="a"
        href="https://www.kazananguvu.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        HanyeLeo
      </MKTypography>
      .
    </MKTypography>
  );
}

export default {
  // Other properties if any
  copyright: <Footer />,
};
