import React, { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKPagination from "components/MKPagination";

// Material Kit 2 PRO React examples
import DefaultNavbar from "hanyeleo/Navbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// History page sections
import EventList from "pages/Home/sections/EventList";

// Routes
import routes from "navroutes";
import footerRoutes from "footer.routes";
import Axios from "utils/Axios";

// Images
import product5 from "assets/images/HanyeIcon.png";
import { useSelector } from 'react-redux';

const itemsPerPage = 10;

function History() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState("");
  const selectedCity = useSelector((state) => state.city);

  // Fetch and filter events based on page, selectedMonth, and selectedCity
  const fetchEvents = async (page = 1, city = "") => {
    const cityParam = city ? `&city=${city}` : "";
    try {
      const response = await Axios.get(`?type=event&event_time=past${cityParam}`);
      const allData = response.data;

      // Apply month filter
      let filteredData = allData;
      if (selectedMonth) {
        filteredData = filteredData.filter((event) =>
          event.date.toLowerCase().includes(selectedMonth.toLowerCase())
        );
      }

      setData(filteredData); // Store filtered data
      setTotalPages(Math.ceil(filteredData.length / itemsPerPage)); // Calculate total pages
    } catch (error) {
      console.error("Failed to fetch events data", error);
    }
  };

  useEffect(() => {
    fetchEvents(page, selectedCity.id);
  }, [page, selectedMonth, selectedCity]);

  // Handle page change for pagination
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Handle month filter change
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setPage(1); // Reset to first page on filter change
  };

  // Paginate the filtered data
  const paginatedData = data.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <>
      <DefaultNavbar routes={routes} relative center />
      <section className="bg-gray-100">
        <Container>
          <div className="flex flex-col md:flex-row items-center justify-between py-12 md:space-x-16 px-6">
            <div className="w-full md:w-1/4 flex items-center md:justify-start">
              <img src={product5} alt="Landing Image" />
            </div>
            <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
              <h1 className="text-3xl md:text-5xl font-bold text-black mb-4">
                Past events on <span className="text-purple-800">HanyeLeo</span>
              </h1>
              <p className="text-gray-700 mb-6">
                Missed an Event? Check out what you missed by viewing the image galleries of our previous events.
              </p>
            </div>
          </div>
        </Container>
      </section>

      <div className="text-center my-16">
        <p className="text-3xl md:text-5xl font-bold text-gray-700 mb-4">Event History</p>
        <p className="font-light text-gray-700 md:px-28 text-center mb-4">
          Click on the Events Below to see Image Galleries of Previous Events{" "}
        </p>
      </div>

      {/* Event List */}
      <EventList events={paginatedData} />

      {/* Pagination */}
      <Container sx={{ mt: 5 }}>
        <MKPagination count={totalPages} page={page} onChange={handlePageChange}>
          <MKPagination item disabled={page === 1} onClick={() => handlePageChange(null, page - 1)}>
            <Icon>keyboard_arrow_left</Icon>
          </MKPagination>
          {[...Array(totalPages).keys()].map((pageNumber) => (
            <MKPagination
              item
              key={pageNumber + 1}
              onClick={() => handlePageChange(null, pageNumber + 1)}
              active={page === pageNumber + 1}
            >
              {pageNumber + 1}
            </MKPagination>
          ))}
          <MKPagination item disabled={page === totalPages} onClick={() => handlePageChange(null, page + 1)}>
            <Icon>keyboard_arrow_right</Icon>
          </MKPagination>
        </MKPagination>
      </Container>

      <DefaultFooter content={footerRoutes} />
    </>
  );
}

export default History;
