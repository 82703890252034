import React, { useState, useEffect } from "react";
import Axios from "utils/Axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MKDatePicker from "components/MKDatePicker";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill"; // Import React Quill
import "react-quill/dist/quill.snow.css"; // Import the Quill stylesheet
import fileUpload from "../../../../assets/images/file_upload.png";
import "./addEvent.scss";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";

// Import Time Validation Functions (Based on earlier conversation)
const validateTimeFormat = (value) => /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i.test(value);

function AddNew() {
  const { id } = useParams(); // Extract the event ID from the URL
  const [eventTitle, setEventTitle] = useState("");
  const [eventDate, setEventDate] = useState(new Date());
  const [eventTime, setEventTime] = useState("10:00 PM");
  const [eventPrice, setEventPrice] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [eventAddressUrl, setEventAddressUrl] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventCover, setEventCover] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState(""); // State for selected city
  const [cities, setCities] = useState([]); // State for list of cities
  const [promoters, setPromoters] = useState([]);
  const [selectedPromoter, setSelectedPromoter] = useState(null);
  const navigate = useNavigate();

  const validateURL = (url) => /^https?:\/\/[^\s$.?#].[^\s]*$/i.test(url);

  useEffect(() => {
    if (id) {
      // Fetch the event data if an ID is provided
      const getSingleEvent = () => {
        Axios.get(`?type=event&id=${id}`)
          .then((response) => {
            const data = response.data;
            setEventTitle(data.title);
            setEventDate(data.date); // Assuming date is in 'YYYY-MM-DDTHH:MM:SSZ' format
            setEventTime(data.time); // Extract time portion
            setEventPrice(data.ticketPrice);
            setEventAddress(data.location);
            setEventAddressUrl(data.location_url);
            setEventDescription(data.description);
            setSelectedCity(data.city);
            setSelectedPromoter(data.promoter);
          })
          .catch((error) => {
            console.error("Failed to fetch event data", error);
          });
      };
      getSingleEvent();
    }
    const fetchPromoters = () => {
      Axios.get("?type=promoters")
        .then((response) => {
          setPromoters(response.data); // Set promoters list
        })
        .catch((error) => {
          console.error("Failed to fetch promoters", error);
        });
    };

    fetchPromoters();

    const fetchCities = () => {
      Axios.get("?type=cities")
        .then((response) => {
          setCities(response.data); // Set cities data
        })
        .catch((error) => {
          console.error("Failed to fetch cities", error);
        });
    };
    fetchCities();
  }, [id]);

  // Time Formatting and Validation Logic
  const handleTimeChange = (e) => {
    let value = e.target.value.toUpperCase(); // Ensure AM/PM is uppercase
    value = value.replace(/[^0-9:AMP ]/gi, ""); // Allow only numbers, colon, AM/PM

    // Handle colon (:) insertion for time formatting
    if (value.length === 2 && !value.includes(":")) {
      value = value + ":"; // Add colon after two digits
    }

    // Handle AM/PM addition
    const timeParts = value.split(" ");
    if (timeParts.length === 2) {
      const lastPart = timeParts[1];
      if (lastPart.length > 2) {
        value = timeParts[0] + " " + lastPart.substring(0, 2); // Limit AM/PM to two characters
      }
    }

    setEventTime(value);

    // Validate after the user has entered enough characters
    if (value.length >= 7) {
      if (!validateTimeFormat(value)) {
        setErrors((prev) => ({ ...prev, eventTime: "Invalid time format (hh:mm AM/PM)" }));
      } else {
        setErrors((prev) => ({ ...prev, eventTime: "" }));
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!eventTitle) newErrors.eventTitle = "Event Title is required";
    if (!eventDate) newErrors.eventDate = "Event Date is required";
    if (!eventTime || !validateTimeFormat(eventTime))
      newErrors.eventTime = "Invalid or missing event time";
    if (!eventAddress) newErrors.eventAddress = "Event Address is required";
    if (!eventCover && !id) newErrors.eventCover = "Event Cover image is required";
    if (!selectedCity) newErrors.selectedCity = "City is required";
    if (!eventAddressUrl) newErrors.eventAddressUrl = "Invalid or missing location URL";
    if (!selectedPromoter) newErrors.selectedPromoter = "Promoter is required"; // Promoter validation
    if (!eventDescription) newErrors.eventDescription = "Description is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddOrUpdateEvent = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("event_title", eventTitle);
    formData.append("event_date", eventDate);
    formData.append("event_time", eventTime);
    formData.append("event_price", eventPrice);
    formData.append("event_address", eventAddress);
    formData.append("event_address_url", eventAddressUrl);
    formData.append("event_description", eventDescription);
    formData.append("city", selectedCity);
    formData.append("promoter", selectedPromoter.value);
    formData.append("type", "event");
    if (eventCover) {
      formData.append("event_header_image1", eventCover);
    }

    if (id) {
      formData.append("event_id", id);
    }

    Axios.post("", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        navigate(`/admin/events`);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="mainAddNewEvent">
      <div className="AddNewEvent">
        <div>
          <div className="lg:mx-12 my-2">
            <div className="text-2xl font-bold py-0 text-primary">
              {id ? "Edit Event" : "New Event"}
            </div>
            <div className="text-sm font-light py-2">
              Admin - Add New Events
            </div>
          </div>
        </div>
        <div className="addNewEventText">
          <Link to="./new">
            <MKButton>
              <Icon color="inherit">aspect_ratio</Icon>
              &nbsp; All Events
            </MKButton>
          </Link>
        </div>
      </div>
      <div className="dataTable">
        <Box sx={{ width: "100%" }} mt={5}>
          <MKBox
            position="relative"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            variant="gradient"
            bgColor="white"
            shadow="sm"
          >
            <MKBox component="section" py={0}>
              <Divider light sx={{ mx: 40, my: 2, width: "20%" }} />
              <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
                <MKBox
                  width="100%"
                  component="form"
                  role="form"
                  autoComplete="off"
                  onSubmit={handleAddOrUpdateEvent}
                >
                  <MKBox p={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          color="dark"
                          label="Event Title"
                          value={eventTitle}
                          onChange={(e) => {
                            setEventTitle(e.target.value);
                            setErrors((prevErrors) => ({ ...prevErrors, eventTitle: "" }));
                          }}
                          fullWidth
                          error={!!errors.eventTitle}
                          helperText={errors.eventTitle}
                          FormHelperTextProps={{
                            sx: { color: "red" },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <MKDatePicker
                          input={{ placeholder: "Select event date" }}
                          value={new Date(eventDate)}
                          onChange={(date) => {
                            const selectedDate = new Date(date);
                            const year = selectedDate.getFullYear();
                            const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
                            const day = String(selectedDate.getDate()).padStart(2, '0');
                            const formattedDate = `${year}-${month}-${day}`;
                            setEventDate(formattedDate);
                            // // const formattedDate = selectedDate.toISOString().split("T")[0];
                            // setEventDate(selectedDate);
                            // setErrors((prevErrors) => ({ ...prevErrors, eventDate: "" }));
                          }}
                          fullWidth
                          error={!!errors.eventDate}
                          helperText={errors.eventDate}
                          FormHelperTextProps={{
                            sx: { color: "red" },
                          }}
                        />
                        {errors.eventDate && (
                          <div className="text-xs text-red-500 mt-1 ml-4">
                            {errors.eventDate}
                          </div>
                        )}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <MKInput
                          type="text"
                          label="Event Time"
                          value={eventTime}
                          onChange={handleTimeChange}
                          fullWidth
                          error={!!errors.eventTime}
                          helperText={errors.eventTime}
                          FormHelperTextProps={{
                            sx: { color: "red" },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <MKInput
                          label="Event Price (Optional)"
                          value={eventPrice}
                          onChange={(e) => {
                            setEventPrice(e.target.value);
                            setErrors((prevErrors) => ({ ...prevErrors, eventPrice: "" }));
                          }}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <MKInput
                          color="dark"
                          label="Event Address"
                          value={eventAddress}
                          onChange={(e) => {
                            setEventAddress(e.target.value);
                            setErrors((prevErrors) => ({ ...prevErrors, eventAddress: "" }));
                          }}
                          fullWidth
                          error={!!errors.eventAddress} // Set error if there is a validation issue
                          helperText={errors.eventAddress} // Display the error message if any
                          FormHelperTextProps={{
                            sx: { color: "red" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          color="dark"
                          label="Event Location Link"
                          value={eventAddressUrl}
                          onChange={(e) => {
                            setEventAddressUrl(e.target.value);
                            setErrors((prevErrors) => ({ ...prevErrors, eventAddressUrl: "" }));
                          }}
                          fullWidth
                          error={!!errors.eventAddressUrl} // Set error if there is a validation issue
                          helperText={errors.eventAddressUrl} // Display the error message if any
                          FormHelperTextProps={{
                            sx: { color: "red" },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Autocomplete
                          disablePortal
                          id="promoter-autocomplete"
                          options={promoters}
                          getOptionLabel={(option) => option.promoter}
                          onChange={(event, value) => {
                            setSelectedPromoter(value);
                            setErrors((prevErrors) => ({ ...prevErrors, selectedPromoter: "" }));
                          }} // Set selected promoter
                          renderInput={(params) => (
                            <TextField {...params} label="Select Promoter" variant="outlined" />
                          )}
                          error={!!errors.selectedPromoter}
                          helperText={errors.selectedPromoter}
                          FormHelperTextProps={{
                            sx: { color: "red" },
                          }}
                        />
                        {errors.selectedPromoter && (
                          <div className="text-xs text-red-500 mt-1 ml-4">
                            {errors.selectedPromoter}
                          </div>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <div className="inputCoverImageDiv">
                          {/* <label htmlFor="fileInput" className="fileInputLabel">
                            <img width="20%" height="20%" src={fileUpload} alt="Upload" />
                          </label> */}
                          <label htmlFor="fileInput" className="fileInputLabel" style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}>
                          <img width="20%" height="20%" src={fileUpload} alt="Upload" style={{ marginRight: '8px' }} />
                          Upload Cover Image
                        </label>
                          <input
                            type="file"
                            id="fileInput"
                            className="eventCoverInput"
                            onChange={(e) => {
                              setEventCover(e.target.files[0]);
                              setErrors((prevErrors) => ({ ...prevErrors, eventCover: "" }));
                            }}
                          />
                          {errors.eventCover && (
                            <div className="text-xs text-red-500 mt-1 ml-4">
                              {errors.eventCover}
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          select
                          label=""
                          value={selectedCity}
                          onChange={(e) => {
                            setSelectedCity(e.target.value);
                            setErrors((prevErrors) => ({ ...prevErrors, selectedCity: "" }));
                          }}
                          fullWidth
                          error={!!errors.selectedCity}
                          helperText={errors.selectedCity}
                          SelectProps={{ native: true }}
                          FormHelperTextProps={{
                            sx: { color: "red" },
                          }}
                        >
                          <option value="">Select a city</option>
                          {cities.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.title}
                            </option>
                          ))}
                        </MKInput>
                      </Grid>

                      <Grid item xs={12}>
                        <ReactQuill
                          theme="snow"
                          value={eventDescription}
                          onChange={(value) => {
                            setEventDescription(value);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              eventDescription: "",
                            }));
                          }}
                          modules={{
                            toolbar: [
                              [{ header: "1" }, { header: "2" }, { font: [] }],
                              [{ list: "ordered" }, { list: "bullet" }],
                              ["bold", "italic", "underline"],
                              ["image", "code-block"],
                            ],
                          }}
                          placeholder="Write event description here..."
                        />
                        {errors.eventDescription && (
                          <div className="text-xs text-red-500 mt-1 ml-4">
                            {errors.eventDescription}
                          </div>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container item justifyContent="center" xs={12} my={2}>
                      <MKButton
                        type="submit"
                        variant="gradient"
                        color="dark"
                        fullWidth
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          `${id ? "Update Event" : "Post Event"}`
                        )}
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </MKBox>
          </MKBox>
        </Box>
      </div>
    </div>
  );
}

export default AddNew;
