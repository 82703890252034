import axios from 'axios';

// Create an Axios instance
const Axios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

// Add a request interceptor
Axios.interceptors.request.use(
    config => {
        // Get the token from local storage
        const token = localStorage.getItem('token');

        // If the token exists, add it to the headers
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        // Continue sending the request even if the token is not found
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
Axios.interceptors.response.use(
    response => {
        console.log(response.status);
        return response;
    },
    error => {
        const { response } = error;
        
        

        // If the status code is 401, clear local storage and redirect to login
        if (response && response.status === 401) {
            console.log(response.status);
            localStorage.clear();
            window.location.href = '/sign-in/';
        }

        return Promise.reject(error);
    }
);

export default Axios;