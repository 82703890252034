import React from "react";
import Nav from "layouts/dashboardNav";
import Sidebar from "layouts/dashboardSidebar";
import Footer from "layouts/dashboardFooter";

// add new event component import
import AddNew from "./AddNew";

//import global scss
import "../../../../styles/global.scss";

function NewEvent() {
  return (
    <>
      <div className="main">
        <div className="navContainer">
          <Nav />
        </div>
        <div className="main-container">
          <div className="sidebarContainer">
            <Sidebar />
          </div>
          <div className="contentContainer">
            <AddNew />
          </div>
        </div>
        <div className="footerContainer">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default NewEvent;
