import React, { useState, useEffect } from "react";
import Axios from "utils/Axios";
import MKButton from "components/MKButton";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import coverImageDefault from "../../../assets/images/groove-sign-up.jpg";
import Avatar from "@mui/material/Avatar";
import truncate from "truncate-html";

function Events() {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // alert(userInfo.access);
  if (userInfo.access < 2)
    navigate(`/`);

  const imageUrl = (url) => {
    return process.env.REACT_APP_BASE_IMAGE_URL + url;
  };

  const toggleModal = () => {
    navigate("/promoter/events/new");
  };

  const gotoEventDetails = (eventId) => {
    navigate(`/promoter/events/single/${eventId}`);
  };

  const truncateHtml = (htmlContent, length = 100) => {
    return truncate(htmlContent, {
      length: length,
      ellipsis: "...",
      keepImageTag: true,
    });
  };

  useEffect(() => {
    Axios.get(`?type=event&token=${token}`)
      .then((response) => {
        const eventData = response.data;
        const flattenedEvents = eventData.flatMap((dateGroup) =>
          dateGroup.events?.map((event) => ({
            id: event.id,
            title: event.title,
            description: event.description,
            time: event.time,
            image: event.image,
            location: event.location,
            ticketPrice: event.ticketPrice,
            date: event.eventDate,
            status: event.is_active,
          }))
        );
        setEvents(flattenedEvents);
      })
      .catch((error) => {
        console.error("Failed to fetch events data", error);
      });
  }, []);

  return (
    <div className="promoterEvents">
      <div className="AddEvent flex justify-between">
        <div>
          <div className="lg:mx-12 my-2">
            <div className="text-2xl font-bold py-0 text-primary">Events</div>
            <div className="text-sm font-light py-2">
              List of your events:
            </div>
          </div>
        </div>
        <div className="addEventText">
          <MKButton onClick={toggleModal}>
            <Icon color="inherit">add_circle</Icon>
            &nbsp; Add Event
          </MKButton>
        </div>
      </div>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6 p-4">
        {events?.map((event) => (
          <div
            onClick={() => gotoEventDetails(event.id)}
            key={event.id}
            className="bg-gray-800 text-white p-4 rounded-lg shadow-lg relative cursor-pointer"
          >
            <div className="flex gap-x-8">
              <Avatar
                alt={event.title}
                src={imageUrl(event.image) || coverImageDefault}
                sx={{ width: 56, height: 56 }}
                variant="rounded"
              />
              <div className="">
                <h2 className="text-lg font-bold">{event.title}</h2>
                <p className="text-xs font-thin text-green-400">{event.location}</p>
                {event.status === "0" ? (
                  <div>
                    <p className="text-xs font-thin text-white bg-yellow-600 max-w-fit px-2 py-0.5 rounded-md">
                      Pending
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mt-10">
              <p
                className="text-sm text-gray-400"
                dangerouslySetInnerHTML={{
                  __html: truncateHtml(event.description, 100),
                }}
              ></p>
            </div>
            <div className="mt-5">
              <p className="text-xs text-gray-400 mt-2">
                Event Date: {event.date}, {event.time}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Events;
