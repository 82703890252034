import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';


const ProtectedRoute = () => {
    //const isLoggedIn = useSelector(state => state.auth.isAuthenticated);
    //return isLoggedIn ? <Outlet /> : <Navigate to='/authentication/sign-in/cover' replace={true} />;
    const userInfoString = localStorage.getItem("userInfo");

    let isLoggedInValue = false;

    if (userInfoString) {
        try {
            const userInfo = JSON.parse(userInfoString);
            isLoggedInValue = userInfo.isAuth;
        } catch (error) {
            console.error('Error parsing userInfo:', error);
        }
    }
    
    return isLoggedInValue ? <Outlet /> : <Navigate to='/sign-in' replace />;
}

export default ProtectedRoute;