import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import { useDispatch } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import CoverLayout from "hanyeleo/login/CoverLayout";
import bgImage from "assets/images/city-profile.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import { GoogleLogin } from "@react-oauth/google";

function Cover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [names, setNames] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordView, setPasswordView] = useState(false);
  const [passwordView2, setPasswordView2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [googleError, setGoogleError] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateForm = () => {
    const newErrors = {};

    if (!names) newErrors.names = "Your name is required";
    if (!email) {
      newErrors.email = "Your email address is required";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!password) {
      newErrors.password = "Password is required";
    }

    if (!password2) {
      newErrors.password2 = "Please confirm your password";
    } else if (password !== password2) {
      newErrors.password2 = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegistration = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    const data = JSON.stringify({
      type: "user",
      names: names,
      email: email,
      password: password,
      password2: password2,
    });

    axios
      .post(process.env.REACT_APP_BASE_URL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data["message"] === "created") {
          navigate("/sign-in/");
        } else {
          console.log("Error, Contact the Admin");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const viewPassword = () => setPasswordView(!passwordView);
  const viewPassword2 = () => setPasswordView2(!passwordView2);

  const handleInputChange = (field, value) => {
    if (field === "names") setNames(value);
    if (field === "email") setEmail(value);
    if (field === "password") setPassword(value);
    if (field === "password2") setPassword2(value);

    // Clear error for specific field on input change
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  // Google Login Handlers
  const handleGoogleLoginSuccess = async (credentialResponse) => {
    const { credential } = credentialResponse;

    // Optionally, display a loading indicator
    setLoading(true);

    // Send the credential (JWT) to your backend for verification
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/google-login/`, { token: credential })
      .then((res) => {
        if (res.status === 200 && res.data.message === "authenticated") {
          // Redirect or perform any action upon successful authentication
          // navigate("/dashboard");

          const data = res.data;
          const userEmail = data.user["email"];
          const userName = data.user["name"];
          const access = data.user["access"];

          // alert("Authenticated");
          // alert(JSON.stringify(data));

          // dispatch(loginSuccess({ userEmail, userName }));
          localStorage.setItem("token", data.jwtToken);
          localStorage.setItem("userInfo", JSON.stringify(data.user));

          if (access === "2") {
            navigate("/promoter/events/");
          } else if (access === "5") {
            navigate("/admin/events/");
          } else {
            navigate("/");
          }

        } else {
          console.log("Authentication failed");
        }
      })
      .catch((err) => {
        console.error(err);
        setGoogleError("Authentication failed. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoogleLoginError = () => {
    console.log("Google Login Failed");
    setGoogleError("Google Login Failed. Please try again.");
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Enter your Name, Email, and Password to sign up for a free account.
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          <MKBox component="form" role="form" onSubmit={handleRegistration}>
            <MKBox mb={2}>
              <MKInput
                type="text"
                variant="standard"
                label="Name"
                value={names}
                onChange={(e) => handleInputChange("names", e.target.value)}
                fullWidth
                error={!!errors.names}
                helperText={errors.names}
                FormHelperTextProps={{
                  sx: { color: "red" },
                }}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="email"
                variant="standard"
                label="Email"
                value={email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
                FormHelperTextProps={{
                  sx: { color: "red" },
                }}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type={passwordView ? "text" : "password"}
                variant="standard"
                label="Password"
                value={password}
                onChange={(e) => handleInputChange("password", e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {password.length > 0 && (
                        <Icon
                          sx={{ cursor: "pointer" }}
                          onClick={viewPassword}
                          color="inherit"
                        >
                          {passwordView ? "visibility_off" : "visibility"}
                        </Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
                error={!!errors.password}
                helperText={errors.password}
                FormHelperTextProps={{
                  sx: { color: "red" },
                }}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type={passwordView2 ? "text" : "password"}
                variant="standard"
                label="Confirm Password"
                value={password2}
                onChange={(e) => handleInputChange("password2", e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {password2.length > 0 && (
                        <Icon
                          sx={{ cursor: "pointer" }}
                          onClick={viewPassword2}
                          color="inherit"
                        >
                          {passwordView2 ? "visibility_off" : "visibility"}
                        </Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
                error={!!errors.password2}
                helperText={errors.password2}
                FormHelperTextProps={{
                  sx: { color: "red" },
                }}
              />
            </MKBox>
            <MKBox mt={3} mb={1}>
              <MKButton
                type="submit"
                variant="gradient"
                color="dark"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Sign Up"}
              </MKButton>
            </MKBox>

            {/* Google Sign-Up Option */}
            <MKBox mt={2} mb={1} textAlign="center">
              <MKTypography variant="body1" color="text">
                Or sign up with
              </MKTypography>
            </MKBox>
            <MKBox display="flex" justifyContent="center" mt={1} mb={2}>
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={handleGoogleLoginError}
              />
            </MKBox>
            {googleError && (
              <MKBox mb={2} textAlign="center">
                <MKTypography variant="caption" color="error">
                  {googleError}
                </MKTypography>
              </MKBox>
            )}

            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="dark">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/sign-in/"
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
