import { configureStore } from "@reduxjs/toolkit";
import userInfoReducer from "./slices/user/userSlice";
import authReducer from "./slices/auth/authSlice"
import cityReducer from "./slices/city/citySlice"

const store = configureStore({
  reducer: {
    auth: authReducer,
    userInfo: userInfoReducer,
    city: cityReducer,
  },
});

export default store;
